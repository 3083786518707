const StorageType = {
  SESSION: "SESSION",
  LOCAL: "LOCAL",
};

export const getStorage = (type) => {
  if (type === StorageType.SESSION) {
    return window.sessionStorage;
  }
  return window.localStorage;
};

const setItem = (type) => (key, value) => {
  getStorage(type).setItem(key, JSON.stringify(value));
};

const getItem = (type) => (key, defaultVal) => {
  const val = getStorage(type).getItem(key);
  if (!val || val === "undefined") return defaultVal;
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

const removeItem = (type) => (key) => {
  getStorage(type).removeItem(key);
};

export const LocalStore = {
  session: {
    get: getItem(StorageType.SESSION),
    set: setItem(StorageType.SESSION),
    remove: removeItem(StorageType.SESSION),
  },
  local: {
    get: getItem(StorageType.LOCAL),
    set: setItem(StorageType.LOCAL),
    remove: removeItem(StorageType.LOCAL),
  },
};

export const setSnapshotToLocalStorage = (snapId, key, data) => {
  const dataFromLocalStorage = getSnapshotFromLocalStorage(snapId);
  let insertedData = dataFromLocalStorage ? { ...dataFromLocalStorage } : {};

  const setNestedValue = (obj, path, value) => {
    const keys = path.split(".");
    let current = obj;
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = current[key] || {};
        current = current[key];
      }
    });
  };

  setNestedValue(insertedData, key, data);

  localStorage.setItem(snapId || "new_snapshot", JSON.stringify(insertedData));
};

export const getSnapshotFromLocalStorage = (snapId) => {
  const result = localStorage.getItem(snapId || "new_snapshot");

  if (result) {
    return JSON.parse(result);
  }

  return null;
};

export const removeSnapshotFromLocalStorage = (snapId) => {
  localStorage.removeItem(snapId || "new_snapshot");
};
